import { Paper } from '@mui/material'
import Form from "./Form";

const Item = ({ item, contact, setContact }) =>
{
    return (
        <Paper className="carousel-image col-12 cont-img carousel-banner" style={{background:`url(${item.image}) no-repeat center center / cover`}}>
            <div className="row mb-5 p-2">
                <div className="mt-4 col-xl-5 col-lg-5 col-sm-12" style={{ zIndex:"3" }}>
                    <h1 className="font-slider-desktop">
                        CONSULTORÍA CONTABLE
                        <br /> Y AUDITORIA
                    </h1>
                    <p className="font-slider-text">
                        SOLICITE MÁS INFORMACIÓN, {" "}
                        <strong>CONVERSEMOS DE SU NEGOCIO</strong>
                        <br />
                    </p>
                    <Form className="mb-5" contact={contact} setContact={setContact} />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 d-md-none d-lg-flex align-items-center carousel-item" style={{color:"rgb(255,255,255)"}}>
                    <div className="row d-flex align-items-center" style={{ zIndex:"3" }}>
                        <div className="col-3 item-title">
                            <h3>{item.title}</h3>
                        </div>
                        <span className="" style={{ width:"5px", height:"150px", backgroundColor:"#FF6304" }}></span>
                        <div className="col-8"  style={{width:"100%"}}>
                            <p className="px-3 text-justify item-description">
                                {item.description}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Paper>
    )
}

export default Item;